<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <router-link :to="{ name: 'autoskola-kandidati-kreiraj' }">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
          >
            {{ $t("DodajKandidata") }}
          </b-button>
        </router-link>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-body :title="$t('Kandidati')">
        <b-row>
          <b-col
            lg="8"
            class="my-1"
          >
            <b-form-group
              :label="$t('Filter')"
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  :placeholder="$t('Pretraga')"
                />

                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    {{ $t('Obrisi') }}
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          v-show="totalRows > 0"
          :items="items"
          :fields="fields"
          striped
          responsive
          class="mb-0 vr"
          style="min-height: 150px;"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(detalji)="row">
            <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
            <b-form-checkbox
              v-model="row.detailsShowing"
              @change="row.toggleDetails"
            />
          </template>
          <template #cell(id)="data">
            <b-button-group size="sm">
              <b-button
                variant="info"
                size="sm"
                @click="linkIzmeni(data.item.id)"
              >
                {{ $t("Izmeni") }}
              </b-button>
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                right
                variant="outline-primary"
                :text="$t('Linkovi')"
                size="sm"
                class="z-3"
              >
                <b-dropdown-item>
                  <router-link
                    :to="{
                      name: 'autoskola-kandidat-ugovori',
                      params: { id: data.item.id }
                    }"
                  >
                    {{ $t('Ugovori') }}
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-item>
                  <router-link
                    :to="{
                      name: 'autoskola-kandidat-podaci-o-obuci-izmeni',
                      params: { id: data.item.id }
                    }"
                  >
                    {{ $t('PodaciOObuci') }}
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-item>
                  <router-link
                    :to="{
                      name: 'autoskola-kandidat-finansije',
                      params: { id: data.item.id }
                    }"
                  >
                    {{ $t('Finansije') }}
                  </router-link>
                </b-dropdown-item>
              </b-dropdown>

            </b-button-group>
          </template>

          <!-- full detail on click -->
          <template #row-details="row">
            <b-card>
              <b-card>
                <b-table-simple
                  small
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-td>
                        {{ $t('IDKandidata') }}
                      </b-td>
                      <b-td>
                        {{ row.item.idkandidata }}
                      </b-td>
                    </b-tr>
                    <b-th
                      :variant="row.item.rateKojeDolaze.length > 0 ? 'warning' : ''"
                      colspan="2"
                    >
                      {{ $t('RateKojeDolazeNaNaplatu') }}
                    </b-th>
                    <b-tr
                      v-for="rata, key in row.item.rateKojeDolaze"
                      :key="key"
                    >
                      <b-td>
                        {{ rata.datum }}
                      </b-td>
                      <b-td>
                        {{ rata.iznos }}
                      </b-td>
                    </b-tr>
                    <b-tr
                      :variant="row.item.potrebna_pomagala ? 'warning' : ''"
                    >
                      <b-th class="col-3">
                        {{ row.item.potrebna_pomagala ? $t('PotrebnaPomagala') : $t('NisuPotrebnaPomagala') }}
                      </b-th>
                      <b-td>{{ row.item.potrebna_pomagala_prikaz }}</b-td>
                    </b-tr>
                    <b-tr
                      :variant="row.item.isticeTeorijskiIspit ? 'warning' : (row.item.status_teorijskog_ispita === 'istekao' ? 'danger' : '')"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumIstekaTeorijskogIspita') }}
                      </b-th>
                      <b-td>{{ row.item.datumIstekaTeorijskogIspita }}</b-td>
                    </b-tr>
                    <b-tr
                      :variant="row.item.istice_lekarsko_uverenje ? 'warning' : (!row.item.vazi_lekarsko ? 'danger' : '')"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumIstekaLekarskogUverenja') }}
                      </b-th>
                      <b-td>{{ row.item.datum_isteka_lekarskog }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.obavezna_prva_pomoc"
                      :variant="row.item.obavezna_prva_pomoc ? 'danger' : ''"
                    >
                      <b-th
                        colspan="2"
                        class="col-3"
                      >
                        {{ row.item.obavezna_prva_pomoc ? $t('ObaveznaPrvaPomoc') : $t('NijeObaveznaPrvaPomoc') }}
                      </b-th>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-card>
            </b-card>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>
        </b-table>
        <b-alert
          v-show="totalRows === 0"
          variant="primary"
          show
        >
          <div
            class="alert-body"
            style="padding: 30px;"
          >
            <span>{{ $t('NemaRezultata') }}</span>
          </div>
        </b-alert>
        <b-pagination
          v-show="totalRows > 0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCardBody,
  BBadge,
  BButtonGroup,
  BCard,
  BPagination,
  BDropdown,
  BDropdownItem,
  BInputGroup,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BRow,
  BTableSimple,
  BTbody,
  BTh,
  BTr,
  BTd,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCardBody,
    BBadge,
    BButtonGroup,
    BCard,
    BPagination,
    BDropdown,
    BDropdownItem,
    BInputGroup,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BRow,
    BTableSimple,
    BTbody,
    BTh,
    BTr,
    BTd,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 20,
      pageOptions: [20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      fields: [
        { key: 'detalji', label: '' },
        { key: 'idkandidata', label: this.$i18n.t('IDKANDIDATA') },
        { key: 'ime_prezime', label: this.$i18n.t('ImePrezime') },
        { key: 'telefon', label: this.$i18n.t('Telefon') },
        { key: 'status', label: this.$i18n.t('status') },
        { key: 'id', label: 'Akcije' },
      ],
      /* eslint-disable global-require */
      items: [],
      status: [{
        aktivan: this.$i18n.t('Aktivan'), neaktivan: this.$i18n.t('Neaktivan'), probni: this.$i18n.t('Probni'),
      },
      {
        aktivan: 'light-success', neaktivan: 'light-danger', probni: 'light-warning',
      }],
      filter: null,
      filterOn: ['idkandidata', 'ime', 'prezime', 'telefon'],
    }
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    pokupiListu() {
      this.$http.get('/autoskole-administratori/kandidati').then(response => {
        this.items = response.data
        this.totalRows = this.items.length
      })
    },
    linkIzmeni(kandidatId) {
      this.$router.push({ name: 'autoskola-kandidati-izmeni', params: { id: kandidatId } })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
